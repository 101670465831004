// Essential Imports
import React from "react"
import { Link } from "react-router-dom"
import { Box } from "@mui/material"

// Component Imports
import MakeSnippet from "../../items/MakeSnippet"
import MessageBox from "../../items/MessageBox"

// Stylesheet Imports
import "../../../styles/Code.css"
import "../../../styles/IntegrationTabs.css"

export default function ApiAccessDrawer({ appliData, customVariables, env = "prod" }) {
  // The code snippet template for the sse post request
  const ssePayloadTemplate = `curl -XPOST 
    -H 'Content-Type: application/json'
    -H 'Apikey: Api-Key <API_KEY>' 
    -d '{
       "payload": {your_query}
    }' '${appliData.app?.sse_sent_url}'`

  const httpPayloadTemplate = `curl -XPOST 
    -H 'Content-Type: application/json'
    -H 'Apikey: Api-Key <API_KEY>' 
    -d '{
       "payload": "Hello World!",
       "env": "${env}"
    }' '${appliData.app?.http_url}'`

  const httpPayloadCustomTemplate = `curl -XPOST 
    -H 'Content-Type: application/json'
    -H 'Apikey: Api-Key <API_KEY>' 
    -d '{
        "payload": "Hello World!",
        "env": "${env}",
        "custom_variables": ${JSON.stringify(customVariables)}
    }' '${appliData.app?.http_url}'`

  return (
    <Box>
      {!appliData.app?.api_key_available && (
        <MessageBox
          type="importantInfo"
          message={
            <React.Fragment>
              You do not have an API key for this AI project yet. You can generate your API key{" "}
              <Link to="/api-keys">here</Link>.
            </React.Fragment>
          }
        />
      )}
      <p style={{ marginBottom: "1rem", display: "block" }}>
        {`This a standard HTTP request method, and will only return the result after completing the full pipeline. To
        learn how to integrate with HTTP request endpoint, check out the `}
        <a
          href="https://river-bottle-af7.notion.site/Integrating-with-HTTP-Request-Endpoint-15847600424a80ac892dc9b92ccf0bc0"
          target="_blank"
          rel="noreferrer noopener"
        >
          documentation here
        </a>
        {` or the `}
        <a href="https://vext.readme.io/reference/http-request-query" target="_blank" rel="noreferrer noopener">
          API reference here
        </a>
        .
      </p>

      <MakeSnippet title="POST">{appliData.app?.http_url}</MakeSnippet>
      {Object.keys(customVariables).length === 0 ? (
        <MakeSnippet title="API Request">{httpPayloadTemplate}</MakeSnippet>
      ) : (
        <MakeSnippet title="API Request">{httpPayloadCustomTemplate}</MakeSnippet>
      )}
    </Box>
  )
}
