import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ReactFlow, { Background, Controls, useEdgesState, useNodesState, useNodesInitialized } from "reactflow"
import { Skeleton } from "@mui/material"
import { cloneDeep } from "lodash"

import ActionDrawer from "../sections/Drawer/ActionDrawer"
import ComponentNode from "../items/ComponentNode"
import CustomEdge from "../items/CustomEdge"
import PopoverHint from "../items/PopoverHint"
import { ErrorContext, SuccessContext } from "../../helper/AlertContext"
import { accountService, appService } from "../../api/services"

import ActionFlowStyles from "./ActionFlow.module.css"
import "reactflow/dist/style.css"

import {
  EDGE_LENGTH,
  NODE_CONFIG,
  NODE_DATASET,
  NODE_FUNCTION,
  NODE_SMART_FUNCTION,
  NODE_HTTP_REQUEST,
  NODE_CODE,
  NODE_QUERY,
  NODE_OUTPUT,
} from "./actionflow.static"

const edgeTypes = {
  buttonAdd: CustomEdge,
}
const edgeConfig = {
  animated: false,
  type: "buttonAdd",
}
const nodeTypes = {
  component: ComponentNode,
}
const nodeTypeMapping = {
  lm: NODE_CONFIG,
  df: NODE_DATASET,
  sf: NODE_SMART_FUNCTION,
  req: NODE_HTTP_REQUEST,
  code: NODE_CODE,
  output: NODE_OUTPUT,
}

const ActionFlow = ({
  customVariables,
  flowInput,
  appliData,
  handleUpdateFlow = () => {},
  handleUpdateFlowConfig = () => {},
  handleUpdateComponent = () => {},
  handleGetProject = () => {},
}) => {
  const [drawerState, setDrawerState] = useState({ type: "", open: false, action: "", index: 0 })
  const [drawerData, setDrawerData] = useState({})
  const [preCreateNodeIndex, setPreCreateNodeIndex] = useState(0) // TODO: relate to custom variables and should be removed
  const [preCreateNode, setPreCreateNode] = useState({ source: null, target: null })
  const [preCreateNodeType, setPreCreateNodeType] = useState("")
  const [isLoadingFlow, setIsLoadingFlow] = useState(true)
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false)
  const [isFitViewComplete, setIsFitViewComplete] = useState(false)
  const [showHintPopup, setShowHintPopup] = useState(false)
  const [flowInstance, setFlowInstance] = useState(null)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const [nodes, setNodes] = useNodesState([{ ...NODE_QUERY, position: { x: 0, y: 0 } }])
  const [edges, setEdges] = useEdgesState([])
  const nodesInitialized = useNodesInitialized()
  const { app_id: appId } = useParams()

  const closeDrawer = () => {
    setDrawerState({ type: "", open: false, action: "", index: 0 })
    setDrawerData({})
  }
  const getAvailableVars = () => {
    if (drawerState.index < 1) return []

    const INPUT_FILE_VARS = ["input_file_1", "input_file_2", "input_file_3", "input_file_4", "input_file_5"]
    const variableList = [
      ...new Set([...["history", "payload"], ...(flowInput?.nodes[drawerState.index - 1]?.available_vars || [])]),
    ]
    let newVariableList = []

    if (drawerState.type === "lm") {
      newVariableList = [...new Set([...variableList, ...INPUT_FILE_VARS])]
    } else if (drawerState.type === "sf") {
      // remove variables 'history' and 'payload' from smart function component
      newVariableList = [...new Set([...variableList, ...INPUT_FILE_VARS])].filter(
        (v) => !["history", "payload"].includes(v),
      )
    } else {
      newVariableList = variableList.filter((variable) => !INPUT_FILE_VARS.includes(variable))
    }
    return newVariableList.sort()
  }
  const openDrawer = ({ type, action, index }) => {
    setPreCreateNodeType(type)
    setDrawerState({ type, open: true, action, index })
  }
  const onClickDialogButton = (event) => {
    const nodeType = event?.currentTarget.value
    const nodeData = nodeTypeMapping[nodeType] || NODE_FUNCTION
    const newNode = {
      id: "temp-node-0",
      ...nodeData,
    }
    const { newNodes, newEdges } = addNodeBetween(nodes, edges, preCreateNode.source, preCreateNode.target, newNode)

    setNodes(newNodes)
    setEdges(newEdges)
    openDrawer({ type: nodeType, action: "create", index: preCreateNodeIndex })
  }
  const onClickEdgeButton = (id) => {
    const [sourceNode, targetNode] = id.split("_").slice(1)

    setPreCreateNode({ source: sourceNode, target: targetNode })
    setPreCreateNodeIndex(getNewNodeIndex(id))
  }
  const onClickNode = async (event) => {
    const nodeId = event.currentTarget.getAttribute("data-node-id")
    const targetNodeIndex = nodes.findIndex((node) => node.id === nodeId)
    const targetNode = nodes.find((node) => node.id === nodeId)

    if (nodeId === "nstart") {
      openDrawer({ type: targetNode.data.name, action: "update", index: 0 })
    } else if (targetNode.id) {
      openDrawer({ type: targetNode.data.name, action: "update", index: targetNodeIndex })
      setIsLoadingDrawer(true)
      try {
        const { data } = await appService.getComponent(targetNode.id)

        setDrawerData({
          ...data.detail,
          componentId: data.id,
          componentType: data.type,
          params: data.params,
          systemPrompt: data.system_prompt,
        })
      } catch (error) {
        setError(true)
        setErrorMsg("Can't get component detail. Please try again later.")
      } finally {
        setIsLoadingDrawer(false)
      }
    }
  }
  const onDeleteNode = async (event) => {
    event.stopPropagation()
    const nodeId = event.currentTarget.getAttribute("data-node-id")
    const tempNodes = cloneDeep(nodes)
    const tempEdges = cloneDeep(edges)
    const { newNodes, newEdges } = removeNodeBetween(tempNodes, tempEdges, nodeId)

    try {
      await handleUpdateFlow(newNodes, newEdges)
      setSuccess(true)
      setSuccessMsg("Project updated.")
    } catch (error) {
      setError(true)
      setErrorMsg(error)
    }
  }
  const getNewNodeIndex = (edgeId) => {
    const [sourceNode] = edgeId.split("_").slice(1)
    return nodes.findIndex((n) => n.id === sourceNode) + 1
  }
  const addNodeBetween = (nodes, edges, nodeId1, nodeId2, newNodeData) => {
    const node1 = nodes.find((node) => node.id === nodeId1)
    const node2 = nodeId2 ? nodes.find((node) => node.id === nodeId2) : null

    if (!node1) {
      console.error("Starting node not found")
    }

    let newPositionX, newPositionY

    if (node2) {
      newPositionX = (node1.position.x + node2.position.x) / 2
      newPositionY = (node1.position.y + node2.position.y) / 2
    } else {
      newPositionX = node1.position.x
      newPositionY = node1.position.y + EDGE_LENGTH
    }

    const newNode = {
      ...newNodeData,
      position: { x: newPositionX, y: newPositionY },
    }
    const newNodes = [...nodes, newNode]

    if (newNodes.find((node) => node.position.y % EDGE_LENGTH !== 0)) {
      let currentY = 0
      let level = 0

      newNodes.sort((a, b) => a.position.y - b.position.y)
      newNodes[0].level = level
      for (let i = 1; i < newNodes.length; i++) {
        if (newNodes[i].position.y !== currentY) {
          newNodes[i].level = ++level
          currentY = newNodes[i].position.y
        } else {
          newNodes[i].level = level
        }
        newNodes[i].position.y = level * EDGE_LENGTH
      }
    }

    const newEdges = edges
      .filter((edge) => !(edge.source === nodeId1 && edge.target === nodeId2))
      .concat([
        { ...edgeConfig, id: `edge_${nodeId1}_${newNodeData.id}`, source: nodeId1, target: newNodeData.id },
        newNodeData.id !== nodeId2 && {
          ...edgeConfig,
          id: `edge_${newNodeData.id}_${nodeId2}`,
          source: newNodeData.id,
          target: nodeId2,
        },
      ])
      .filter(Boolean)
    return { newNodes, newEdges }
  }
  const removeNodeBetween = (nodes, edges, nodeId) => {
    const nodeToRemove = nodes.find((node) => node.id === nodeId)

    if (!nodeToRemove) {
      console.error("Node to remove not found")
    }

    const incomingEdge = edges.find((edge) => edge.target === nodeId)
    const outgoingEdge = edges.find((edge) => edge.source === nodeId)
    const newNodes = nodes.filter((node) => node.id !== nodeId)
    const newEdges = edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)
    let currentY = 0
    let level = 0

    newNodes[0].level = level
    for (let i = 1; i < newNodes.length; i++) {
      if (newNodes[i].position.y !== currentY) {
        newNodes[i].level = ++level
      } else {
        newNodes[i].level = level
      }
      newNodes[i].position.y = level * EDGE_LENGTH
    }
    if (incomingEdge && outgoingEdge) {
      newEdges.push({
        ...edgeConfig,
        id: `edge_${incomingEdge.source}_${outgoingEdge.target}`,
        source: incomingEdge.source,
        target: outgoingEdge.target,
      })
    }
    return { newNodes, newEdges }
  }
  const updateComponentWithErrorCheck = async (componentId, payload) => {
    const response = await handleUpdateComponent(componentId, payload)

    if (response.status !== 200) {
      throw response.data?.[0]
    }
    return response
  }
  const handleOnSaveDrawer = async (payload) => {
    const messageMapping = {
      df: "Vector Database",
      lm: "LLM",
      af: "Tool",
      sf: "Agent",
      code: "Code",
      req: "HTTP Request",
      output: "Output",
    }
    const actionMapping = {
      create: "Created",
      update: "Updated",
    }

    if (!payload) {
      closeDrawer()
      return
    }
    setIsLoadingDrawer(true)
    try {
      if (drawerState.action === "create") {
        const flowResponse = await handleUpdateFlow(nodes, edges)
        const componentId = flowResponse.components[preCreateNodeIndex - 1].id

        if (drawerState.type === "df") {
          await updateComponentWithErrorCheck(componentId, {
            detail: { id: payload.datasetId },
            params: { top_k: payload.topk, rag_filter: payload.rag_filter },
            system_prompt: payload.systemPrompt,
          })
        } else if (drawerState.type === "lm") {
          const configResponse = await appService.createConfig(payload)

          await updateComponentWithErrorCheck(componentId, {
            detail: { id: configResponse.data.id },
            system_prompt: payload.systemPrompt,
          })
          accountService.updateTaskList({ tune_config: true })
        } else if (drawerState.type === "code") {
          const codeResponse = await appService.createCode({
            name: payload.name,
            language: payload.language,
            code: payload.code,
            params_json: {
              input: payload.params.input.map((i) => i.variable),
              output: payload.params.output.map((i) => i.variable),
            },
          })

          await updateComponentWithErrorCheck(componentId, {
            detail: { id: codeResponse.data.id },
            params: {
              input: payload.params.input,
            },
          })
        } else if (drawerState.type === "af") {
          await updateComponentWithErrorCheck(componentId, {
            detail: { id: payload.functionId },
            system_prompt: payload.systemPrompt,
            params: payload.params,
          })
        } else if (drawerState.type === "sf") {
          const functionsResponse = await appService.createSmartFunctionList({
            name: `${flowResponse.components[preCreateNodeIndex - 1].name}-${new Date().getTime()}`,
            a_functions: payload.a_functions.map((item) => item.id),
          })

          await updateComponentWithErrorCheck(componentId, {
            detail: { id: functionsResponse.data.id },
            params: {
              a_functions: payload.a_functions,
              dataframe: payload.dataframe,
              model: payload.model,
              max_iter: payload.max_iter,
            },
            system_prompt: payload.systemPrompt,
          })
        } else if (drawerState.type === "req") {
          const requestResponse = await appService.createHttpRequest({
            name: `Http Request ${new Date().toISOString().split("T")[0]}`,
            url: payload.url,
            method: payload.method,
            body_type: payload.body_type,
          })

          await updateComponentWithErrorCheck(componentId, {
            detail: { id: requestResponse.data.id },
            params: { requests: payload.requests },
          })
        }
        setNodes((prevValue) => {
          prevValue[preCreateNodeIndex].componentId = componentId
          return prevValue
        })
      } else if (drawerState.action === "update") {
        if (drawerState.type === "df") {
          await updateComponentWithErrorCheck(payload.componentId, {
            detail: { id: payload.datasetId },
            params: { top_k: payload.topk, rag_filter: payload.rag_filter },
            system_prompt: payload.systemPrompt,
          })
        } else if (drawerState.type === "lm") {
          const componentResponse = await updateComponentWithErrorCheck(payload.componentId, {
            system_prompt: payload.systemPrompt,
          })
          const configId = componentResponse.data.detail.id

          await appService.updateConfig(configId, payload)
          accountService.updateTaskList({ tune_config: true })
        } else if (drawerState.type === "code") {
          await updateComponentWithErrorCheck(payload.componentId, {
            detail: { id: payload.codeId },
            params: {
              input: payload.params.input,
            },
          })
          await appService.updateCode(payload.codeId, {
            language: payload.language,
            code: payload.code,
            params_json: {
              input: payload.params.input.map((i) => i.variable),
              output: payload.params.output.map((i) => i.variable),
            },
          })
        } else if (drawerState.type === "af") {
          await updateComponentWithErrorCheck(payload.componentId, {
            detail: { id: payload.functionId },
            system_prompt: payload.systemPrompt,
            params: payload.params,
          })
        } else if (drawerState.type === "sf") {
          await updateComponentWithErrorCheck(payload.componentId, {
            detail: { id: payload.smartFunctionId },
            params: {
              a_functions: payload.a_functions,
              dataframe: payload.dataframe,
              model: payload.model,
              max_iter: payload.max_iter,
            },
            system_prompt: payload.systemPrompt,
          })
        } else if (drawerState.type === "req") {
          await appService.updateHttpRequest(payload.requestId, {
            name: payload.name,
            url: payload.url,
            method: payload.method,
            body_type: payload.body_type,
          })
          await updateComponentWithErrorCheck(payload.componentId, {
            detail: { id: payload.requestId },
            params: { requests: payload.requests },
          })
        } else if (drawerState.type === "query") {
          await handleUpdateFlowConfig({ custom_variable_bank: payload })
        } else if (drawerState.type === "output") {
          await appService.updateOutput(payload.ouputId, { output_variables: payload.outputVariables })
        }
      }
      await handleGetProject()

      if (messageMapping[drawerState.type]) {
        setSuccess(true)
        setSuccessMsg(`${messageMapping[drawerState.type]} ${actionMapping[drawerState.action]}`)
      }
      closeDrawer()
    } catch (error) {
      setError(true)
      if (error.response?.data?.model_kwargs?.length > 0) {
        setErrorMsg(error.response?.data?.model_kwargs[0])
      } else if (error.response?.data?.aws_content?.length > 0) {
        setErrorMsg(error.response?.data?.aws_content[0])
      } else if (error.response?.data?.url) {
        setErrorMsg(error.response.data)
      } else if (error.response?.data) {
        setErrorMsg(error.response?.data[0])
      } else {
        setErrorMsg(error)
      }
    } finally {
      setIsLoadingDrawer(false)
    }
  }

  useEffect(() => {
    const isFlowUpdated = nodes?.length === flowInput.nodes?.length + 1

    if (isFlowUpdated && appId) {
      setIsLoadingFlow(false)
    }
  }, [nodes])

  useEffect(() => {
    setTimeout(() => {
      if (edges?.length === 1 && document.querySelector("#addActionBtn")) {
        setShowHintPopup(true)
      }
    }, 100)
  }, [edges])

  useEffect(() => {
    // read component list from project
    if (flowInput.nodes?.length) {
      const nodes = [{ ...NODE_QUERY, position: { x: 0, y: 0 } }]
      const edges = [
        {
          ...edgeConfig,
          source: "nstart",
          target: flowInput.nodes[0]?.id,
          id: `edge_nstart_${flowInput.nodes[0]?.id}`,
        },
      ]

      flowInput?.nodes.map((node) => {
        const nodeType = nodeTypeMapping[node.type] || NODE_FUNCTION
        nodes.push({ ...nodeType, id: node.id, level: node.level, position: node.position, type: "component" })
      })
      flowInput?.edges.map((edge) => {
        edges.push({
          ...edge,
          ...edgeConfig,
          id: `edge_${edge.source}_${edge.target}`,
        })
      })
      setNodes(nodes)
      setEdges(edges)
    }
  }, [flowInput])

  useEffect(() => {
    // trigger fit-view method only once after react-flow is rendered the first time
    if (nodesInitialized && flowInstance && !isLoadingFlow && !isFitViewComplete) {
      flowInstance.fitView({ padding: 0.2 })
      setIsFitViewComplete(true)
    }
  }, [nodesInitialized, flowInstance, isLoadingFlow])

  useEffect(() => {
    setIsLoadingFlow(true)
  }, [appId])

  return (
    <>
      {isLoadingFlow && (
        <div className={ActionFlowStyles.loadingMask}>
          <Skeleton height={72} width={240} />
          <Skeleton height={72} width={240} />
          <Skeleton height={72} width={240} />
        </div>
      )}
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          data: { ...node.data, level: node.level, onClickNode, onDeleteNode },
        }))}
        edges={edges.map((edge) => ({
          ...edge,
          data: { onClickDialogButton, onClickEdgeButton },
        }))}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        maxZoom={1}
        minZoom={0.2}
        onInit={(instance) => {
          setFlowInstance(instance)
        }}
      >
        <Background />
        <Controls showInteractive={false} />
      </ReactFlow>
      {showHintPopup && (
        <PopoverHint elementSelector="#addActionBtn" side="right" description="Add an action here to get started" />
      )}
      <ActionDrawer
        availableVars={getAvailableVars()}
        drawerData={drawerData}
        drawerSequence={drawerState.index}
        customVariables={customVariables}
        appliData={appliData}
        onCloseDrawer={() => {
          if (drawerState.action === "create") {
            const { newNodes, newEdges } = removeNodeBetween(nodes, edges, "temp-node-0")
            setNodes(newNodes)
            setEdges(newEdges)
          }
          closeDrawer()
        }}
        onSaveDrawer={(payload) => {
          handleOnSaveDrawer(payload)
        }}
        type={preCreateNodeType}
        isOpen={drawerState.open}
        isLoading={isLoadingDrawer}
      />
    </>
  )
}

export default ActionFlow
