import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ReactFlowProvider } from "reactflow"
import { Alert, Button, ClickAwayListener, Drawer, IconButton, Skeleton, Tooltip } from "@mui/material"
import {
  Close as CloseIcon,
  Code as CodeIcon,
  MoreHorizOutlined as MoreHorizIcon,
  ScienceOutlined as ScienceIcon,
  LockOutlined as LockIcon,
  PeopleOutline as PeopleIcon,
} from "@mui/icons-material"
import { Can } from "@casl/react"

import ActionFlow from "../components/template/ActionFlow"
import BasicSwitch from "../components/items/Toggle"
import SelectField from "../components/items/SelectField"
import TextArea from "../components/items/TextArea"
import LocalLoadingBar from "../components/items/LocalLoadingBar"
import ConfirmationDialog from "../components/items/ConfirmationDialog"
import BasicTooltip from "../components/items/BasicTooltip"
import TestWindow from "../components/children/TestWindow"
import ShareResourceForm from "../components/children/ShareResourceForm"
import ApiAccessDrawer from "../components/sections/Drawer/ApiAccessDrawer"
import { accountService, appService } from "../api/services"
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import { TitleContext } from "../helper/TitleContext"
import { AbilityContext } from "../helper/AbilityContext"

import ProjectDetailStyles from "../styles/ProjectDetail.module.css"
import "../styles/Items.css"

const DEFAULT_EDIT_STATE = { name: false, description: false }

const ProjectDetail = () => {
  const navigate = useNavigate()
  const { app_id: appId } = useParams()
  const [workspace, setWorkspace] = useState({})
  const [canPublish, setCanPublish] = useState(false)
  const [canShareProject, setCanShareProject] = useState(false)
  const [customVariables, setCustomVariables] = useState(null)
  const [projectName, setProjectName] = useState("")
  const [projectDescription, setProjectDescription] = useState("")
  const [projectStatus, setProjectStatus] = useState(false)
  const [flowData, setFlowData] = useState({ nodes: [], edges: [] })
  const [memory, setMemory] = useState(null)
  const [enableLog, setEnableLog] = useState(true)
  const [enableCitation, setEnableCitation] = useState(false)
  const [environmentInput, setEnvironmentInput] = useState("dev")
  const [currentVersionId, setCurrentVersionId] = useState(null)
  const [hasPublished, setHasPublished] = useState(false)
  const [actionMenuState, setActionMenuState] = useState({ show: false, type: "" })
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [drawerState, setDrawerState] = useState({ show: false, type: "" })
  const [playgroundData, setPlaygroundData] = useState(null)
  const [isEditingField, setIsEditingField] = useState(DEFAULT_EDIT_STATE)
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [canEnableMemory, setCanEnableMemory] = useState(true)
  const [apiView, setApiView] = useState(true)
  const [shareProjectPayload, setShareProjectPayload] = useState(null)
  const [shareFormData, setShareFormData] = useState({})
  const [isShared, setIsShared] = useState(false)
  const { isLoading, setIsLoading } = useContext(LoadingContext)
  const { setSuccess, setSuccessMsg } = useContext(SuccessContext)
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const { setTitle } = useContext(TitleContext)
  const ability = useContext(AbilityContext)
  const abilityRules = ability.rules
  const environmentOptions = [
    { label: "Development", value: "dev" },
    { label: "Production", value: "prod", disabled: !hasPublished },
  ]

  const handleCreateProject = async () => {
    const payload = {
      is_close: false,
      name: "Untitled",
      description: "This is an incredibly smart AI.",
    }

    try {
      const { data } = await appService.createProject(payload)

      accountService.updateTaskList({ create_app: true })
      setSuccess(true)
      setSuccessMsg("AI project created")
      navigate(`/ai-projects/${data.id}`)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const handleDeletion = async () => {
    try {
      setIsLocalLoading(true)
      await appService.removeProject(appId)
      navigate("/ai-projects")
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleShareProject = async () => {
    try {
      setIsLocalLoading(true)
      await appService.grantProjectAccess2Users(appId, shareProjectPayload)
      setSuccess(true)
      setSuccessMsg("User added to the project.")
      handleReadProject()
      toggleDialog()
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleGetAccessUsers = async () => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.getProjectAccessUsers(appId)
      setCanShareProject(true)
      setIsShared(data.users.length > 0 || data.all)
      setShareFormData(data)
    } catch (error) {
      if (error.response.status !== 403) {
        setError(true)
        setErrorMsg(error.message)
      }
    } finally {
      setIsLocalLoading(false)
    }
  }
  const getCurrentVersion = (versionList) => {
    /*
     - the first object with is_publish: true has the latest production version ID;
     - the first object with is_dev: true has the latest development version ID;
     - if no objects have is_publish: false, production option should be disabled
     */
    const prodVersion = versionList.find((version) => version.is_publish)
    const devVersion = versionList.find((version) => version.is_dev)

    return { prod: prodVersion?.id || null, dev: devVersion?.id || null }
  }
  const handleReadProject = async () => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.getProject(appId)
      const { prod, dev } = getCurrentVersion(data.versions)

      setHasPublished(!!prod)
      if (!prod && environmentInput === "prod") {
        throw new Error("Can't find any production version. Please publish your project first.")
      }
      if (!dev && environmentInput === "dev") {
        throw new Error("Cannot find any development version.")
      }

      const versionId = environmentInput === "prod" ? prod : dev

      setCanPublish(data.versions.length > 1 && prod !== dev)
      setCurrentVersionId(versionId)
      await handleReadVersion(versionId)
      updateHeader(data.name)
      setWorkspace(data.organization)
      setProjectName(data.name)
      setProjectDescription(data.description)
      setProjectStatus(!data.is_close)
      setEnableLog(data.is_logging)
      setEnableCitation(data.is_citation)
      setPlaygroundData({ app: { http_url: data.http_url, id: data.id, api_key_available: data.api_key_available } })
    } catch (error) {
      if (error.response.status === 404) {
        navigate(`/ai-projects/`)
      }
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleReadVersion = async (id) => {
    try {
      const { data } = await appService.getFlow(id)
      setFlowData({ nodes: data.components, edges: data.edges })
      setMemory(data.is_need_memory)
      setCustomVariables(data.custom_variable_bank)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const handleUpdateFlow = async (flowNodes, flowEdges) => {
    const components = flowNodes
      .filter((node) => node.id !== "nstart")
      .map((node) => ({
        type: node.data.name,
        name: node.data.label,
        position_x: node.position.x,
        position_y: node.position.y,
        ...(node.id.indexOf("temp-node-") === 0 ? { nid: node.id.replace("temp-node-", "n") } : { id: node.id }),
      }))
    const edges = flowEdges
      .filter((edge) => edge.source !== "nstart")
      .map((edge) => ({
        source: edge.source.indexOf("temp-node-") === 0 ? edge.source.replace("temp-node-", "n") : edge.source,
        target: edge.target.indexOf("temp-node-") === 0 ? edge.target.replace("temp-node-", "n") : edge.target,
      }))

    const findConfig = components.find((item) => item.type === "lm" || item.type === "sf")
    const payload = {
      components,
      edges,
    }

    setIsLocalLoading(true)
    if (!findConfig) {
      payload.is_need_memory = false
    }
    try {
      const { data } = await appService.updateFlow(currentVersionId, payload)

      await handleReadProject()
      setFlowData({ nodes: data.components, edges: data.edges })
      setMemory(data.is_need_memory)
      return data
    } catch (error) {
      throw error.response?.data?.[0] || error.message
    } finally {
      setIsLocalLoading(false)
    }
  }
  const handleUpdateComponent = async (componentId, payload) => {
    try {
      const response = await appService.updateComponent(componentId, payload)
      return response
    } catch (error) {
      return error.response
    }
  }
  const handleUpdateFlowConfig = async (payload) => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.updateFlow(currentVersionId, payload)

      await handleReadProject()
      setMemory(data.is_need_memory)
      setCustomVariables(data.custom_variable_bank)
      setSuccess(true)
      setSuccessMsg("Project updated.")
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.[0] || error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const gotoLogsPage = () => {
    sessionStorage.setItem("selectedAppId", appId)
    navigate("/logs")
  }
  const onChangeEnvType = (event) => {
    if (event.target.value) {
      setEnvironmentInput(event.target.value)
    }
  }
  const updateHeader = (projectName) => {
    setTitle(projectName)
    sessionStorage.setItem("appName", projectName)
  }
  const toggleEditFieid = (event) => {
    const field = event?.target.getAttribute("value")
    const fieldMapping = {
      name: projectName,
      description: projectDescription,
    }

    if (field) {
      setIsEditingField({ ...DEFAULT_EDIT_STATE, [field]: fieldMapping[field] })
    } else {
      setIsEditingField(DEFAULT_EDIT_STATE)
    }
  }
  const toggleDrawer = (type = "") => {
    if (type && typeof type === "string") {
      setDrawerState({ show: true, type })
    } else {
      setDrawerState({ show: false, type })
    }
  }
  const toggleProjectStatus = (value) => {
    setProjectStatus(value)
    handleUpdateProject({ is_close: !value })
  }
  const togglePgView = () => {
    setApiView(!apiView)
  }
  const toggleActionMenu = (type = "") => {
    const { show } = actionMenuState
    setActionMenuState({ show: !show, type })
  }
  const toggleDialog = (type = "") => {
    const { show } = dialogState
    setDialogState({ show: !show, type })
  }
  const handleUpdateProject = async (payload) => {
    setIsLocalLoading(true)
    try {
      const { data } = await appService.updateProject(appId, payload)

      updateHeader(data.name)
      setEnableLog(data.is_logging)
      setEnableCitation(data.is_citation)
      setSuccess(true)
      setSuccessMsg("Project updated.")
    } catch (error) {
      setProjectStatus(projectStatus)
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  // const handleSaveVersion = async () => {
  //   try {
  //     await appService.createVersion(currentVersionId)
  //     setSuccess(true)
  //     setSuccessMsg("Draft saved.")
  //   } catch (error) {
  //     setError(true)
  //     setErrorMsg(error.response?.data?.text)
  //   }
  // }
  const handlePublish = async () => {
    try {
      await appService.publishVersion(currentVersionId)
      setSuccess(true)
      setSuccessMsg("Changes published to the production branch.")
      handleReadProject()
    } catch (error) {
      setError(true)
      setErrorMsg(error.response?.data?.text)
    }
  }

  useEffect(() => {
    const asyncUseEffect = async () => {
      setIsLoading(true)
      if (!appId) {
        await handleCreateProject()
        setFlowData({ nodes: [], edges: [] })
      } else {
        await handleReadProject()
      }
      setIsLoading(false)
    }

    asyncUseEffect()
  }, [appId])
  useEffect(() => {
    if (flowData.nodes) {
      setCanEnableMemory(flowData.nodes.find((item) => item.type === "lm" || item.type === "sf"))
    }
  }, [flowData])
  useEffect(() => {
    if (workspace.type === "p") {
      setCanShareProject(false)
    } else if (workspace.type) {
      handleGetAccessUsers()
    }
  }, [workspace])
  useEffect(() => {
    if (appId) {
      handleReadProject()
    }
    if (environmentInput === "prod") {
      ability.update([...abilityRules, { inverted: true, action: "update", subject: "Project" }])
    } else {
      ability.update(abilityRules.filter((rule) => !rule.inverted))
    }
  }, [environmentInput])

  return (
    <>
      <section className={ProjectDetailStyles.editableArea}>
        {isLoading || !flowData.nodes ? (
          <div className={ProjectDetailStyles.header}>
            <div style={{ display: "block", width: "100%" }}>
              <Skeleton variant="rounded" animation="wave" height={24} width="25%" />
              <Skeleton variant="rounded" animation="wave" height={16} width="40%" sx={{ marginTop: 0.5 }} />
            </div>
          </div>
        ) : (
          <div className={ProjectDetailStyles.header}>
            <div>
              <Can I="update" a="Project" ability={ability}>
                <BasicSwitch
                  defaultChecked={projectStatus}
                  handleSave={toggleProjectStatus}
                  disabled={isLocalLoading}
                />
              </Can>
              <div className={ProjectDetailStyles.fieldContainer}>
                {isEditingField.name ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => {
                      if (projectName) {
                        handleUpdateProject({ name: projectName })
                      } else {
                        setProjectName(isEditingField.name)
                      }
                      toggleEditFieid()
                    }}
                  >
                    <div>
                      <TextArea
                        value={projectName}
                        mLength={100}
                        onChange={setProjectName}
                        onPressEnter={() => {
                          if (projectName) {
                            handleUpdateProject({ name: projectName })
                            toggleEditFieid()
                          }
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                ) : (
                  <h3 className="appConfig" onClick={toggleEditFieid} value="name">
                    {projectName}
                  </h3>
                )}
                {isEditingField.description ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    onClickAway={() => {
                      if (projectDescription) {
                        handleUpdateProject({ description: projectDescription })
                      } else {
                        setProjectDescription(isEditingField.description)
                      }
                      toggleEditFieid()
                    }}
                  >
                    <div>
                      <TextArea
                        value={projectDescription}
                        mLength={150}
                        onChange={setProjectDescription}
                        onPressEnter={() => {
                          if (projectDescription) {
                            handleUpdateProject({ description: projectDescription })
                            toggleEditFieid()
                          }
                        }}
                      />
                    </div>
                  </ClickAwayListener>
                ) : (
                  <p onClick={toggleEditFieid} style={{ marginTop: 4 }} className="appConfig" value="description">
                    {projectDescription}
                  </p>
                )}
              </div>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <SelectField
                  options={environmentOptions}
                  value={environmentInput}
                  onChange={onChangeEnvType}
                  sx={{ width: "124px" }}
                />
                <Can I="update" a="Project" ability={ability}>
                  {environmentInput === "dev" && (
                    <>
                      <Button onClick={handlePublish} variant="contained" disabled={!canPublish}>
                        Publish
                      </Button>
                      {/* <Button onClick={handleSaveVersion} variant="outlined">
                        Save draft
                      </Button> */}
                    </>
                  )}
                </Can>
              </div>
            </div>
            <div className={ProjectDetailStyles.actionArea}>
              <Tooltip title={flowData.nodes?.length <= 1 && "Please add at least one action to the workflow."}>
                <span>
                  <Button
                    onClick={() => toggleDrawer("playground")}
                    disabled={flowData.nodes?.length <= 1}
                    variant="outlined"
                    startIcon={<ScienceIcon />}
                  >
                    Playground
                  </Button>
                </span>
              </Tooltip>
              <Button onClick={() => toggleDrawer("api")} variant="outlined" startIcon={<CodeIcon />}>
                API Access
              </Button>
              {canShareProject ? (
                <Can I="share" a="Project" ability={ability}>
                  <Tooltip title={flowData.nodes?.length <= 1 && "Please add at least one action to the workflow."}>
                    <span>
                      <Button
                        onClick={() => toggleDialog("shareProject")}
                        variant="outlined"
                        startIcon={isShared ? <PeopleIcon /> : <LockIcon />}
                        disabled={flowData.nodes?.length <= 1}
                      >
                        {isShared ? "Shared" : "Share"}
                      </Button>
                    </span>
                  </Tooltip>
                </Can>
              ) : null}
              <IconButton onClick={() => toggleActionMenu("projectMenu")}>
                <MoreHorizIcon />
              </IconButton>
              {actionMenuState.show && actionMenuState.type === "projectMenu" ? (
                <ClickAwayListener onClickAway={toggleActionMenu}>
                  <div className={ProjectDetailStyles.moreOptions}>
                    <Can I="update" a="Project" ability={ability}>
                      <Tooltip
                        placement="top"
                        title={canEnableMemory ? null : "Add an Agent or LLM to the project to enable memory."}
                      >
                        <span>
                          <Button
                            onClick={() => handleUpdateFlowConfig({ is_need_memory: !memory })}
                            disabled={isLocalLoading || !canEnableMemory}
                          >
                            Memory: <span>{memory ? "On" : "Off"}</span>
                          </Button>
                        </span>
                      </Tooltip>
                      <Button
                        onClick={async () => {
                          setIsLocalLoading(true)
                          await handleUpdateProject({ is_citation: !enableCitation })
                          setIsLocalLoading(false)
                        }}
                        disabled={isLocalLoading}
                      >
                        Citation: <span>{enableCitation ? "On" : "Off"}</span>
                      </Button>
                      <Button
                        onClick={async () => {
                          setIsLocalLoading(true)
                          await handleUpdateProject({ is_logging: !enableLog })
                          setIsLocalLoading(false)
                        }}
                        disabled={isLocalLoading}
                      >
                        Logs: <span>{enableLog ? "On" : "Off"}</span>
                      </Button>
                    </Can>
                    <Button onClick={gotoLogsPage}>View Logs</Button>
                    <Can I="delete" a="Project" ability={ability}>
                      <Button onClick={() => toggleDialog("deleteProject")} color="error">
                        Delete
                      </Button>
                    </Can>
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
        )}
        <div style={{ height: "60vh", position: "relative", display: "flex", flexDirection: "column" }}>
          <LocalLoadingBar localLoading={isLocalLoading} />
          {isLocalLoading && <div className={ProjectDetailStyles.overlay} />}
          <Alert
            icon={false}
            severity={environmentInput === "dev" ? "warning" : "success"}
            sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, display: "flex", justifyContent: "center" }}
          >
            {environmentInput === "dev" ? (
              <>
                {"You're in the development branch. Changes "}
                <b>will not take effect</b> {"until published."}
              </>
            ) : (
              <>You are in the production branch. To make changes, switch to the development branch.</>
            )}
          </Alert>
          <div style={{ flex: 1 }}>
            <ReactFlowProvider>
              <ActionFlow
                key={appId}
                customVariables={customVariables}
                flowInput={flowData}
                appliData={playgroundData}
                handleGetProject={handleReadProject}
                handleUpdateFlow={handleUpdateFlow}
                handleUpdateFlowConfig={handleUpdateFlowConfig}
                handleUpdateComponent={handleUpdateComponent}
              />
            </ReactFlowProvider>
          </div>
        </div>
      </section>
      <Drawer anchor="right" open={drawerState.show} onClose={toggleDrawer}>
        <section className={ProjectDetailStyles.drawer}>
          <header className={ProjectDetailStyles.title}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <CloseIcon onClick={toggleDrawer} className="hoverIcon" style={{ cursor: "pointer" }} />
              <p style={{ fontSize: "1.3rem", fontWeight: 700 }}>
                {drawerState.type === "playground" && "Playground"}
                {drawerState.type === "api" && "API Access"}
              </p>
            </div>
            {drawerState.type === "playground" && (
              <IconButton onClick={() => toggleActionMenu("apiViewMenu")}>
                <MoreHorizIcon />
              </IconButton>
            )}
            {actionMenuState.show && actionMenuState.type === "apiViewMenu" ? (
              <ClickAwayListener onClickAway={toggleActionMenu}>
                <div className={ProjectDetailStyles.morePgOptions}>
                  <div>
                    API View <BasicTooltip tooltip={"Display detailed JSON request and response data."} />
                  </div>
                  <BasicSwitch defaultChecked={apiView} handleSave={togglePgView} disabled={isLocalLoading} />
                </div>
              </ClickAwayListener>
            ) : null}
          </header>
          <div className={ProjectDetailStyles.main}>
            {drawerState.type === "playground" && (
              <TestWindow
                appliData={playgroundData}
                viewLog={gotoLogsPage}
                customVariables={customVariables}
                apiView={apiView}
                showUploadIcon={canEnableMemory}
                env={environmentInput}
              />
            )}
            {drawerState.type === "api" && (
              <ApiAccessDrawer appliData={playgroundData} customVariables={customVariables} env={environmentInput} />
            )}
          </div>
        </section>
      </Drawer>
      <ConfirmationDialog
        open={dialogState.show && dialogState.type === "deleteProject"}
        handlePrimary={handleDeletion}
        handleSecondary={toggleDialog}
        title="Confirm Delete"
        content="Are you sure you want to delete this project? This action cannot be undone."
        primaryButtonText={isLocalLoading ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={isLocalLoading}
      />
      {appId && dialogState.show && dialogState.type === "shareProject" ? (
        <ConfirmationDialog
          open={true}
          handlePrimary={handleShareProject}
          handleSecondary={toggleDialog}
          title={`Share “${projectName}”`}
          content={
            <ShareResourceForm formData={shareFormData} onChange={setShareProjectPayload} refresh={handleReadProject} />
          }
          primaryButtonVariant="contained"
          secondaryButtonVariant="outlined"
          primaryButtonText={isLocalLoading ? "Sharing..." : "Share"}
          primaryButtonDisabled={isLocalLoading || !canShareProject}
        />
      ) : null}
    </>
  )
}

export default ProjectDetail
