import React, { useEffect } from "react"

const OAuthRedirect = () => {
  useEffect(() => {
    const params = window.location.search
    // Store params in localStorage
    localStorage.setItem('oauthParams', params)
    
    if (window.opener) {
      window.opener.postMessage({ source: "Vext Authentication", payload: params })
      window.close()
    } else {
      window.close()
    }
  }, [])

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <p>Authentication successful. Redirecting...</p>
    </div>
  )
}

export default OAuthRedirect
