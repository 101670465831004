// Library Imports
import React, { useEffect, useState } from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

import PlanChip from "./PlanChip"

const SelectField = ({ options = [], value, onChange, disabled, error = false, sx, label, divStyle, placeholder = "Select..." }) => {
  const [defaultValue, setDefaultValue] = useState("")

  useEffect(() => {
    setDefaultValue(value)
  }, [value])

  const renderOptions = () => {
    return [
      <MenuItem key="placeholder" value="">
        <em style={{ color: "#3d3d3d" }}>{placeholder}</em>
      </MenuItem>,
      ...options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {option.icon && (
            <img 
              src={option.icon} 
              alt="" 
              style={{ 
                width: '20px', 
                height: '20px', 
                marginRight: '8px',
                verticalAlign: 'middle' 
              }} 
            />
          )}
          <span>{option.label}</span>
          {option.badge && <PlanChip plan={option.badge} />}
        </MenuItem>
      )),
    ]
  }

  return (
    <div style={divStyle}>
      <FormControl variant="outlined" disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select value={defaultValue} onChange={onChange} displayEmpty sx={sx} label={label} error={error}>
          {renderOptions()}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectField
