export const ROLE_PERMISSIONS = {
  // workspace permissions
  owner: [
    { action: "create", subject: "Workspace" },
    { action: "manage", subject: "Member" },
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "delete", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "delete", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
    { action: "delete", subject: "ApiKey" },
    { action: "read", subject: "WorkspaceUsage" },
    { action: "read", subject: "WorkspaceMemberList" },
  ],
  admin: [
    { action: "manage", subject: "Member" },
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "delete", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "delete", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
    { action: "delete", subject: "ApiKey" },
    { action: "read", subject: "WorkspaceUsage" },
    { action: "read", subject: "WorkspaceMemberList" },
  ],
  editor: [
    { action: "share", subject: "Project" },
    { action: "read", subject: "Project" },
    { action: "create", subject: "Project" },
    { action: "update", subject: "Project" },
    { action: "share", subject: "Dataset" },
    { action: "read", subject: "Dataset" },
    { action: "create", subject: "Dataset" },
    { action: "update", subject: "Dataset" },
    { action: "create", subject: "DataSource" },
    { action: "delete", subject: "DataSource" },
    { action: "read", subject: "DataSourceOriginalFile" },
    { action: "create", subject: "ApiKey" },
  ],
  read: [
    { action: "read", subject: "Project" },
    { action: "read", subject: "Dataset" },
  ],
  // organization permissions
  org_owner: [
    { action: "read", subject: "OrganizationGeneral" },
    { action: "read", subject: "OrganizationUsage" },
    { action: "read", subject: "OrganizationMemberList" },
  ],
  org_admin: [
    { action: "read", subject: "OrganizationGeneral" },
    { action: "read", subject: "OrganizationUsage" },
    { action: "read", subject: "OrganizationMemberList" },
  ],
  org_member: [{ action: "read", subject: "OrganizationGeneral" }],
}
