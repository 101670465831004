import { isEnterpriseUser } from "../utils"

const fetchRequest = (url, options) => {
  const isCustom = localStorage.getItem("vext_enterprise")
  let BASE_URL = `${process.env.REACT_APP_API_ROOT}/api/v2`

  if (isCustom || isEnterpriseUser()) {
    if (isCustom === "test" || isEnterpriseUser() === "test") {
      BASE_URL = "https://apidevtest.vextapp.com/api/v2"
    } else {
      BASE_URL = "https://apidev-ntu.vextapp.com/api/v2"
    }
  }
  return fetch(`${BASE_URL}${url}`, options)
}

export default fetchRequest
