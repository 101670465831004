import React, { useState, useEffect, useContext } from "react"
import { Button, Card, CircularProgress, IconButton } from "@mui/material"
import { Close as CloseIcon } from "@mui/icons-material"

import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"

export default function DataDetailGoogleDrive({
  localLoading,
  setLocalLoading,
  setIntegrationFileId,
  setIntegrationFileType,
  setIntegrationFileName,
  setDataValid,
}) {
  const errorContext = useContext(ErrorContext)
  const [authenticating, setAuthenticating] = useState(true)
  const [buttonText, setButtonText] = useState("Connect Google Drive")
  const [inputValue, setInputValue] = useState(null)
  const [picker, setPicker] = useState(null)
  const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false)
  const [isGapiLoaded, setIsGapiLoaded] = useState(false)
  const [accessToken, setAccessToken] = useState(null)

  const mapMimeTypeToDisplayText = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "Text"
      case "application/vnd.google-apps.document":
        return "Docs"
      case "application/vnd.google-apps.spreadsheet":
        return "Sheets"
      case "text/csv":
        return "CSV"
      case "application/json":
        return "JSON"
      case "application/xml":
        return "XML"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "XLSX"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "DOCX"
      case "application/pdf":
        return "PDF"
      default:
        return "Unknown"
    }
  }

  const mapMimeTypeToInternalValue = (mimeType) => {
    switch (mimeType) {
      case "text/plain":
        return "file"
      case "application/vnd.google-apps.document":
        return "google_docs"
      case "application/vnd.google-apps.spreadsheet":
        return "google_sheets"
      case "text/csv":
        return "csv"
      case "application/json":
        return "file"
      case "application/xml":
        return "file"
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlsx"
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx"
      case "application/pdf":
        return "pdf"
      default:
        return "Unknown"
    }
  }

  const handleFileSelect = async (event) => {
    const file = event.detail

    try {
      setInputValue(file)
      setDataValid(true)
      setIntegrationFileId(file.id)
      setIntegrationFileName(file.name)
      setIntegrationFileType(mapMimeTypeToInternalValue(file.mimeType))
    } catch (error) {
      console.error("Error getting file:", error)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message || "Error getting file")
    }
  }

  const openGooglePicker = () => {
    const createPicker = () => {
      try {
        if (!window.google || !window.google.picker) {
          throw new Error("Google Picker API not loaded")
        }

        const picker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
          .enableFeature(window.google.picker.Feature.MULTISELECT_DISABLED)
          .addView(
            new window.google.picker.DocsView()
              .setIncludeFolders(false)
              .setMode(window.google.picker.DocsViewMode.LIST)
              .setSelectFolderEnabled(false),
          )
          .addView(
            new window.google.picker.DocsView()
              .setMimeTypes("application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet")
              .setIncludeFolders(false),
          )
          .setOAuthToken(accessToken)
          .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
          .setAppId(process.env.REACT_APP_GOOGLE_APP_ID)
          .setOrigin(window.location.protocol + "//" + window.location.host)
          .setCallback((data) => {
            if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.PICKED) {
              const file = data[window.google.picker.Response.DOCUMENTS][0]

              handleFileSelect({
                detail: {
                  id: file.id,
                  name: file.name,
                  mimeType: file.mimeType,
                  url: file.url,
                },
              })
            } else if (data[window.google.picker.Response.ACTION] === window.google.picker.Action.CANCEL) {
              // picker closed without selecting any file
            }
          })
          .build()

        picker.setVisible(true)
        setPicker(picker)
      } catch (error) {
        errorContext.setError(true)
        errorContext.setErrorMsg("Open Google Picker error")
      }
    }

    // Only create picker if APIs are loaded
    if (isGoogleApiLoaded && isGapiLoaded) {
      createPicker()
    } else {
      errorContext.setError(true)
      errorContext.setErrorMsg("Google APIs not yet loaded")
    }
  }

  // Trigger receive message from pop up
  const receiveMessage = async (event) => {
    const { data, origin } = event

    // Verify the source and origin
    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")

    window.removeEventListener("message", receiveMessage)
    localStorage.removeItem("oauthParams")

    try {
      const { data } = await request.post("/third_party/google_picker/oauth", {
        code: code,
      })

      if (data?.access_token) {
        setIsGoogleApiLoaded(true)
        setButtonText("Authenticated")
        setAccessToken(data.access_token)
      } else {
        throw new Error("Failed to get access token")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  const authenticateGoogle = async () => {
    const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=560, top=100, left=100"

    window.removeEventListener("message", receiveMessage)
    try {
      const { data } = await request.get("/third_party/google_picker/oauth")

      window.open(data.text, "Google Authentication", strWindowFeatures)
      window.addEventListener("message", receiveMessage)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg("Failed to get access token")
    }
  }
  const checkOauthStatus = async () => {
    const { data } = await request.get("/third_party/google/is_need_google_oauth")

    if (!data.text) {
      setIsGoogleApiLoaded(true)
      setButtonText("Authenticated")
      setAccessToken(data.access_token)
    }
    setAuthenticating(false)
  }
  const initializeGooglePicker = () => {
    const gapiScript = document.createElement("script")

    gapiScript.id = "google-picker-api"
    gapiScript.src = "https://apis.google.com/js/api.js"
    gapiScript.onload = () => {
      if (window.gapi) {
        window.gapi.load("picker", {
          callback: () => {
            setIsGapiLoaded(true)
          },
          onerror: () => {
            errorContext.setError(true)
            errorContext.setErrorMsg("Google Picker loaded failed. ")
          },
        })
      }
    }
    document.body.appendChild(gapiScript)
  }

  useEffect(() => {
    checkOauthStatus()
    initializeGooglePicker()

    return () => {
      const gapiScript = document.getElementById("google-picker-api")

      if (gapiScript) {
        gapiScript.remove()
      }
    }
  }, [])

  return (
    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
      {buttonText === "Authenticated" ? (
        <div style={{ width: "100%" }}>
          <h5>Select File</h5>
          <Button variant="outlined" onClick={openGooglePicker} style={{ width: "100%" }}>
            Search File...
          </Button>
          {inputValue?.name && (
            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "left" }}>
              <Card
                variant="outlined"
                sx={{ display: "inline-flex", justifyContent: "space-between", p: 1, width: "50%" }}
              >
                <div style={{ fontSize: "0.8rem", textAlign: "left", overflow: "hidden" }}>
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                    {inputValue.name}
                  </div>
                  <div style={{ color: "#3d3d3d80", marginTop: "0.25rem" }}>
                    <i>{mapMimeTypeToDisplayText(inputValue.mimeType)}</i>
                  </div>
                </div>
                <IconButton
                  size="small"
                  onClick={() => {
                    setInputValue(null)
                    setDataValid(false)
                    setIntegrationFileId("")
                    setIntegrationFileName("")
                    setIntegrationFileType("")
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Card>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              onClick={() => {
                authenticateGoogle()
                setInputValue(null)
              }}
              style={{ cursor: "pointer", textDecoration: "underline", marginTop: "1rem", fontSize: "0.7rem" }}
            >
              Switch Account
            </p>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateGoogle}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <img src="/images/integrations/googledrive.svg" style={{ marginRight: "0.5rem" }} />
              {buttonText}
            </>
          )}
        </Button>
      )}
    </div>
  )
}
