import React, { useContext, useEffect } from "react"
import { Button } from "@mui/material"
import { ErrorContext } from "../../helper/AlertContext"
import { LoadingContext } from "../../helper/LoadingContext"
import { accountService, organizationService } from "../../api/services"

const SsoLogin = ({ url, finalizeLogin, getCountry }) => {
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)

  const processAuthParams = async (payload) => {
    console.log("payload", payload)
    try {
      loadingContext.setIsLoading(true)
      const country = await getCountry()

      if (!payload.text?.userPrincipalName) {
        throw new Error("No email received from SSO provider")
      }

      const token = payload.text.access_token
      const email = payload.text.userPrincipalName
      const firstName = payload.text.givenName || ""
      const lastName = payload.text.surname || ""

      const user = {
        firstName,
        lastName,
        email,
        ipCountry: country,
      }

      const loginPayload = {
        email,
        password: null,
        account_type: "ntu_azure",
        token,
      }

      let response = await fetch(`${url}/check_account`, {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: { "Content-Type": "application/json" },
        credentials: "omit",
      })

      if (response.status === 200) {
        const payload = {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          avatar_url: `https://ui-avatars.com/api/?bold=true&name=${encodeURIComponent(
            firstName.charAt(0),
          )}&background=random`,
          ip_country: user.ipCountry,
          time_zone: null,
          language: null,
          account_type: "ntu_azure",
          password: null,
        }
        await accountService.createAccount(payload)
        await accountService.login(loginPayload)
        await organizationService.getOrganizationList()
        finalizeLogin(user)
      } else if (response.status === 406) {
        await accountService.login(loginPayload)
        await organizationService.getOrganizationList()
        const accountInfo = await accountService.getAccount()
        const user = {
          firstName: accountInfo.data.first_name,
          lastName: accountInfo.data.last_name,
          email: accountInfo.data.email,
          profilePic: accountInfo.data.avatar_url,
          ipCountry: accountInfo.data.ip_country,
        }
        finalizeLogin(user)
      } else {
        throw new Error("Unknown error has occurred.")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg("Error processing authentication response: " + error.message)
    } finally {
      loadingContext.setIsLoading(false)
    }
  }

  const receiveMessage = async (event) => {
    const { data, origin } = event

    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      console.warn("Received invalid message event", { source: data?.source, origin });
      return;
    }

    try {
      const params = new URLSearchParams(String(data.payload).slice(1))
      const code = params.get("code")
      
      if (!code) {
        throw new Error("No authorization code received from OAuth provider");
      }
      
      window.removeEventListener("message", receiveMessage)
      localStorage.removeItem("oauthParams")

      const response = await fetch(`${url}/azure_oauth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: "omit",
        body: JSON.stringify({
          code,
          enterprise: "ntu",
          // test: "test"
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`OAuth request failed: ${response.status} ${errorData.message || response.statusText}`);
      }
      
      const responseData = await response.json();
      await processAuthParams(responseData);
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(`Authentication error: ${error.message}`)
      loadingContext.setIsLoading(false);
    }
  }

  const handleSsoLogin = async () => {
    loadingContext.setIsLoading(true)

    try {
      window.removeEventListener("message", receiveMessage)
      
      const state = Math.random().toString(36).substring(2, 15);
      localStorage.setItem("oauthState", state);
      
      const authUrl = `https://login.microsoftonline.com/${encodeURIComponent(
        process.env.REACT_APP_NTU_AZURE_APP_ID,
      )}/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
        process.env.REACT_APP_NTU_AZURE_CLIENT_ID,
      )}&response_type=code&redirect_uri=${encodeURIComponent(
        `${window.location.origin}/oauth-redirect`,
      )}&response_mode=query&scope=${encodeURIComponent("email profile openid User.Read")}&state=${state}`
      
      const name = "Microsoft Authentication"
      const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=720, top=100, left=100"

      const authWindow = window.open(authUrl, name, strWindowFeatures)
      if (!authWindow) {
        throw new Error("Popup blocked. Please allow popups for this site.");
      }
      
      window.addEventListener("message", receiveMessage, false)
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(`Failed to initiate login: ${error.message}`)
      loadingContext.setIsLoading(false)
    }
  }

  return (
    <Button variant="outlined" fullWidth onClick={handleSsoLogin} disabled={loadingContext.isLoading}>
      <img
        src="/images/models/microsoft.svg"
        alt="Microsoft logo"
        style={{ width: "20px", height: "20px", marginRight: "8px" }}
      />
      Sign in with Azure AD
    </Button>
  )
}

export default SsoLogin
