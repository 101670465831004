// Essential Imports
import React, { useState, useContext, useEffect } from "react"

// Component Imports
import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"

// Library Imports
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Skeleton from "@mui/material/Skeleton"

export default function DataDetailNotion({
  localLoading,
  setLocalLoading,
  setIntegrationFileName,
  setIntegrationFileId,
  setDataValid,
}) {
  const errorContext = useContext(ErrorContext)
  const [userId, setUserId] = useState(null)
  const [authenticating, setAuthenticating] = useState(true)
  const [buttonText, setButtonText] = useState("Connect Notion")
  const [pages, setPages] = useState([])
  const [nextPageToken, setNextPageToken] = useState(null)
  const [query, setQuery] = useState("")
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState(null)
  const [inputPages, setInputPages] = useState(null)

  let windowObjectReference = null
  let previousUrl = null

  // Function to fetch Notion pages
  const fetchNotion = async (nextPageToken = null, newQuery = "", loadType) => {
    if (loadType === "newLoad") {
      setPages([])
      setNextPageToken(null)
    }

    setLocalLoading(true)

    let endpoint = `/third_party/notion/pages/50`
    let params = []

    if (nextPageToken) {
      params.push(`next_page_token=${nextPageToken}`)
    }
    if (newQuery) {
      params.push(`q=${newQuery}`)
    }
    if (params.length > 0) {
      endpoint += `?${params.join("&")}`
    }

    try {
      const response = await request.get(endpoint)

      // Filter out item.parent.type "database_id"
      // Include item.parent.type "workspace"
      // object === database && page

      let dataToSet = response.data.text.results
      let filteredData = dataToSet.filter(
        (item) => item?.properties?.title?.title?.[0]?.plain_text || item?.properties?.Name?.title?.[0]?.plain_text,
      )

      if (filteredData) {
        if (isSearchActive) {
          setPages(filteredData)
        } else {
          setPages((prevItems) => [...prevItems, ...filteredData])
        }

        if (response.data.text.next_cursor) {
          // const match = response.data.text.next_cursor.match(/cursor=([^&]*)/);
          // const cursorValue = match ? match[1] : null;
          setNextPageToken(response.data.text.next_cursor)
        } else {
          setNextPageToken(null)
        }
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  // Infinite scroll for Autocomplete
  const handleScroll = (event) => {
    if (localLoading) return

    const { scrollTop, clientHeight, scrollHeight } = event.target
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.75
    if (isBottom && nextPageToken) {
      setIsSearchActive(false)
      fetchNotion(nextPageToken, "", "continueLoad")
    }
  }

  // Handle search
  const handleSearch = (event, value) => {
    clearTimeout(searchTimeout)

    // Check for partial matches in existing items
    const partialMatch = pages.some((page) => page.name && page.name.toLowerCase().startsWith(value.toLowerCase()))

    if (partialMatch) {
      return // Don't fetch if there's a partial or full match
    }

    setQuery(value)

    const newTimeout = setTimeout(() => {
      setIsSearchActive(true)
      if (value === "") {
        setIsSearchActive(false)
        fetchNotion(null, "", "newLoad")
      } else {
        fetchNotion(null, value, "newLoad")
      }
    }, 500)

    setSearchTimeout(newTimeout)
  }

  // Check if users already authenticated
  useEffect(() => {
    const fetchUserId = request.get(`/account`);
    const fetchOauthStatus = request.get(`/third_party/notion/is_need_notion_oauth`);

    Promise.all([fetchUserId, fetchOauthStatus])
      .then(([userIdResponse, oauthStatusResponse]) => {
        setUserId(userIdResponse.data.id);
        if (!oauthStatusResponse.data.text) {
          setButtonText("Authenticated");
          fetchNotion(null, "", "newLoad");
        }
        setAuthenticating(false);
      })
      .catch(error => {
        errorContext.setError(true);
        errorContext.setErrorMsg(error.message);
      });

    return () => {
      clearTimeout(searchTimeout);
    };
  }, []);

  // Trigger receive message from pop up
  const receiveMessage = async (event) => {
    const { data, origin } = event

    // Verify the source and origin
    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")
    window.removeEventListener("message", receiveMessage)
    localStorage.removeItem("oauthParams")

    try {
      const response = await request.post(`/third_party/notion/oauth`, {
        code: code,
      })

      if (response.data.text === "ok") {
        setButtonText("Authenticated")
        fetchNotion(null, "", "newLoad")
      }
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    }
  }

  // Start Google authentication process
  const authenticateNotion = () => {
    window.removeEventListener("message", receiveMessage)

    const authUrl = `https://api.notion.com/v1/oauth/authorize?client_id=${encodeURIComponent(
      process.env.REACT_APP_NOTION_CLIENT_ID,
    )}&response_type=code&owner=user&redirect_uri=${encodeURIComponent(`${window.location.origin}/oauth-redirect`)}`
    const name = "Notion Authentication"
    const strWindowFeatures = "toolbar=no, menubar=no, width=980, height=720, top=100, left=100"

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
    } else if (previousUrl !== authUrl) {
      windowObjectReference = window.open(authUrl, name, strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    // add the listener for receiving a message from the popup
    window.addEventListener("message", receiveMessage, false)
    // assign the previous URL
    previousUrl = authUrl
  }

  return (
    <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
      {buttonText === "Authenticated" ? (
        <div style={{ width: "100%" }}>
          <h5>Page</h5>
          <Autocomplete
            value={inputPages}
            options={pages}
            getOptionLabel={(option) =>
              `${option.properties.title?.title[0]?.plain_text || option.properties.Name?.title[0]?.plain_text || ""}`
            }
            isOptionEqualToValue={(option, value) => pages.some((page) => page.id === value.id)}
            renderOption={(props, option) => (
              <li {...props} key={`${option.id}`}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      {option.properties.title?.title[0]?.plain_text || option.properties.Name?.title[0]?.plain_text}
                    </div>
                    <small style={{ color: "#3d3d3d80" }}>{option.id}</small>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Select Page..." />}
            sx={{ width: "100%" }}
            ListboxProps={{
              onScroll: (event) => handleScroll(event),
            }}
            onInputChange={handleSearch}
            onChange={(event, value) => {
              if (value) {
                setInputPages(value)
                setIntegrationFileId(value.id)
                setIntegrationFileName(
                  value.properties.title?.title[0].plain_text || value.properties.Name?.title[0]?.plain_text,
                )
                setDataValid(true)
              } else {
                setInputPages(null)
                setDataValid(false)
                setIntegrationFileId("")
                setIntegrationFileName("")
              }
            }}
            noOptionsText={
              localLoading ? (
                <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                  <Skeleton variant="text" animation="wave" width="100%" height={30} />
                  <Skeleton variant="text" animation="wave" width="100%" height={20} />
                </div>
              ) : (
                "No Result"
              )
            }
          />
        </div>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateNotion}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? <CircularProgress size={24} /> : buttonText}
        </Button>
        // <Button
        //   variant="contained"
        //   onClick={authenticateNotion}
        //   style={{ width: "100%", marginTop: "1rem", textTransform: "none" }}
        // >
        //   {buttonText}
        // </Button>
      )}
    </div>
  )
}
