import { useContext } from "react"
import { ErrorContext } from "../helper/AlertContext"
import { LoadingContext } from "../helper/LoadingContext"

// Create a custom hook for the contexts
export function useFetchWithErrors() {
  const errorContext = useContext(ErrorContext)
  const loadingContext = useContext(LoadingContext)

  return async (url, options) => {
    try {
      const response = await fetch(url, options)

      if (response.status >= 400 && response.status !== 406) {
        throw new Error("Server responded with an error.")
      }
      return response
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message || "An error occurred.")
      loadingContext.setIsLoading(false)
      throw error
    }
  }
}

export default useFetchWithErrors