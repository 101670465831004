// Essential Imports
import React, { useState, useEffect, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ClickAwayListener, IconButton, Switch, Tooltip } from "@mui/material"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { Can } from "@casl/react"

// Component Imports
import { SuccessContext, ErrorContext } from "../../helper/AlertContext"
import ConfirmationDialog from "./ConfirmationDialog"
import { appService } from "../../api/services"
import { AbilityContext } from "../../helper/AbilityContext"

// Stylesheet Imports
import "../../styles/Items.css"

const DIALOG_DELETE = "delete"

export default function AppItem({ app, getApps, toggle }) {
  const [canDuplicate, setCanDuplicate] = useState(true)
  const [dialogState, setDialogState] = useState({ show: false, type: "" })
  const [showDropdown, setShowDropdown] = useState(false)
  const [isChecked, setIsChecked] = useState(toggle)
  const [localLoading, setLocalLoading] = useState(false)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  const handleToggleChange = async (event) => {
    const isActive = event.target.checked

    setLocalLoading(true)
    setIsChecked(isActive)
    try {
      await appService.updateProject(app.id, { is_close: !isActive })
      successContext.setSuccess(true)
      successContext.setSuccessMsg(`${isActive ? "Enable" : "Disable"} project.`)
    } catch (error) {
      setIsChecked(!isActive)
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  // Click on settings
  const handleIconClick = (event) => {
    event.stopPropagation()
    setShowDropdown(!showDropdown)
  }

  const handleItemClick = () => {
    navigate(`/ai-projects/${app.id}`)
  }

  const handleCopyProject = async (event) => {
    event.stopPropagation()
    event.preventDefault()

    if (!canDuplicate) return
    setLocalLoading(true)
    try {
      await appService.copyProject(app.id)
      setShowDropdown(false)
      getApps()
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Project duplicated.")
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  const handleOpenDialog = (event, type) => {
    event.stopPropagation()
    event.preventDefault()
    setShowDropdown(false)
    setDialogState({ show: true, type })
  }

  const handleCloseDialog = () => {
    setDialogState({ show: false, type: "" })
  }

  // Handle app deletion
  const handleDeletion = async () => {
    try {
      setLocalLoading(true)
      await appService.removeProject(app.id)
      successContext.setSuccess(true)
      successContext.setSuccessMsg("Project successfully deleted")
      setDialogState({ show: false, type: "" })
      getApps()
    } catch (error) {
      errorContext.setError(true)
      errorContext.setErrorMsg(error.message)
    } finally {
      setLocalLoading(false)
    }
  }

  // Sync local isChecked state with server data when the component re-renders
  useEffect(() => {
    setIsChecked(toggle)
  }, [toggle])
  useEffect(() => {
    if (app.versions) {
      setCanDuplicate(app.versions.some((v) => v.is_publish))
    }
  }, [app])

  return (
    <>
      <div className="itemsContainer" id={app.id} onClick={handleItemClick}>
        <div className="appInfo">
          <h3>{app.name}</h3>
          <p>{app.description}</p>
          <div className="appDate">
            {app.create_by.id && (
              <div style={{ alignItems: "center", display: "flex", gap: "0.3rem" }}>
                {"Created by "}
                <img src={app.create_by.avatar_url} style={{ width: "20px", height: "20px", borderRadius: "50%" }} />
                {app.create_by.first_name} {app.create_by.last_name}
              </div>
            )}
            <i>
              {"Last changed: "}
              {new Date(app.updated_time).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </i>
          </div>
        </div>
        <Can I="update" a="Project" ability={ability}>
          <div style={{ position: "relative" }} className="appAction">
            <div onClick={(event) => event.stopPropagation()}>
              <Switch checked={isChecked} onChange={handleToggleChange} disabled={localLoading} />
            </div>
            <IconButton onClick={handleIconClick} sx={{ p: 0 }}>
              <MoreHorizOutlinedIcon className="hoverIcon" />
            </IconButton>
            {showDropdown && (
              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleIconClick}>
                <div className="option">
                  <Tooltip
                    title={
                      canDuplicate
                        ? ""
                        : `You need to publish your Development branch to the Publish branch before duplicating this project.`
                    }
                  >
                    <Link
                      className="link"
                      onClick={handleCopyProject}
                      style={{
                        ...(!canDuplicate && {
                          opacity: 0.5,
                          cursor: "not-allowed",
                        }),
                      }}
                    >
                      Duplicate
                    </Link>
                  </Tooltip>
                  <Link className="link" to={`/ai-projects/${app.id}`} onClick={(event) => event.stopPropagation()}>
                    Manage
                  </Link>
                  <Link
                    className="link"
                    style={{ color: "#F44336" }}
                    onClick={(event) => handleOpenDialog(event, DIALOG_DELETE)}
                  >
                    Delete Project
                  </Link>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </Can>
      </div>
      <ConfirmationDialog
        open={dialogState.show && dialogState.type === DIALOG_DELETE}
        handlePrimary={handleDeletion}
        handleSecondary={handleCloseDialog}
        title="Confirm Delete"
        content="Are you sure you want to delete this project? This action cannot be undone."
        primaryButtonText={localLoading ? "Deleting..." : "Delete"}
        primaryButtonColor="error"
        primaryButtonDisabled={localLoading}
      />
    </>
  )
}
