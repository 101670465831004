import React, { useContext, useEffect, useState } from "react"

import BasicTooltip from "../items/BasicTooltip"
import SelectField from "../items/SelectField"
import { MODELS } from "../../utils/constants"
import { AbilityContext } from "../../helper/AbilityContext"

import ActionDrawerStyles from "../sections/Drawer/ActionDrawer.module.css"

const ModelSection = ({ allowModels = [], isLoadingForm, modelInput, modelId, maxToken, credit, vendor, onChangeModel }) => {
  const [modelList, setModelList] = useState(MODELS)
  const ability = useContext(AbilityContext)

  const getVendorLogo = (vendor) => {
    const vendorKey = vendor?.toLowerCase().replace(/\s+/g, '')
    return `/images/models/${vendorKey}.svg`
  }

  const modelOptions = modelList.map(model => ({
    ...model,
    icon: getVendorLogo(model.vendor)
  }))

  useEffect(() => {
    if (allowModels?.length) {
      setModelList(MODELS.filter((m) => allowModels.includes(m.value)))
    }
  }, [allowModels])

  return (
    <div className={ActionDrawerStyles.flex}>
      <h4>
        Model
        <BasicTooltip tooltip="Which LLM model to deploy to operate your LLM pipeline." />
      </h4>
      <div>
        <div style={{ flex: 1 }}>
          <SelectField
            options={modelOptions}
            value={modelInput}
            onChange={onChangeModel}
            disabled={isLoadingForm || !ability.can("update", "Project")}
          />
          <div
            style={{
              fontSize: "0.8rem",
              marginTop: "0.5rem",
              color: "#3d3d3d80",
              fontStyle: "italic",
              textAlign: "right",
            }}
          >
            {modelId && <div>Model ID: {modelId}</div>}
            {maxToken && <div>Max Output Tokens: {maxToken}</div>}
            {credit && <div>Credit Cost: {credit}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelSection
