import React, { useCallback, useContext, useEffect, useState } from "react"
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Skeleton,
  TextField,
} from "@mui/material"
import { debounce } from "lodash"

import { ErrorContext } from "../../helper/AlertContext"
import request from "../../api/axios"

const PAGE_SIZE = 100

export default function DataDetailServiceNow({ handleIdSelection = () => {} }) {
  const [authenticating, setAuthenticating] = useState(false)
  const [isCheckingAuthentication, setIsCheckingAuthentication] = useState(false)
  const [incidentList, setIncidentList] = useState([])
  const [isLocalLoading, setIsLocalLoading] = useState(false)
  const [isIncremental, setIsIncremental] = useState(false)
  const [knowledgeList, setKnowledgeList] = useState([])
  const [selectedType, setSelectedType] = useState("knowledge")
  const [selectedItems, setSelectedItems] = useState({})
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [query, setQuery] = useState()
  const [knowledgePage, setKnowledgePage] = useState(1)
  const [incidentPage, setIncidentPage] = useState(1)
  const [incrementalPage, setIncrementalPage] = useState(1)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const { setError, setErrorMsg } = useContext(ErrorContext)

  const authenticateServiceNow = async () => {
    setAuthenticating(true)
    try {
      let authUrl = ""
      const { data } = await request.get("/third_party/servicenow/oauth")
      authUrl = data.text

      window.removeEventListener("message", receiveMessage)
      localStorage.removeItem("oauthParams")
      window.open(
        authUrl,
        "servicenow Authentication",
        "toolbar=no, menubar=no, width=980, height=720, top=100, left=100",
      )
      window.addEventListener("message", receiveMessage, false)
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setAuthenticating(false)
    }
  }
  const receiveMessage = async (event) => {
    const { data, origin } = event

    if (!data || data.source !== "Vext Authentication" || origin !== window.location.origin) {
      return
    }

    const params = new URLSearchParams(String(data.payload).slice(1))
    const code = params.get("code")
    window.removeEventListener("message", receiveMessage)

    try {
      const response = await request.post("/third_party/servicenow/oauth", { code })
      if (response.data.text === "ok") {
        setIsAuthenticated(true)
      } else {
        throw new Error("Authentication failed")
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const debounceFetchList = useCallback(
    debounce((params) => {
      if (selectedType === "incident") {
        fetchIncidentList(params)
      } else if (selectedType === "knowledge") {
        fetchKnowledgeList(params)
      }
    }, 500),
    [selectedType],
  )
  const fetchIncidentList = async (params) => {
    setIsLocalLoading(true)
    if (params.page > 1) {
      setIsLoadMore(true)
    } else {
      setIncidentPage(1)
      setIncrementalPage(1)
    }
    try {
      const getUrl = "/third_party/servicenow/incident" + (isIncremental ? "/incremental" : "")
      const { data } = await request.get(getUrl, { params })

      if (params?.page > 1) {
        setIncidentList([...incidentList, ...data.result])
      } else {
        setIncidentList(data.result)
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const fetchKnowledgeList = async (params) => {
    setIsLocalLoading(true)
    if (params.page > 1) {
      setIsLoadMore(true)
    } else {
      setKnowledgePage(1)
    }
    try {
      const { data } = await request.get("/third_party/servicenow/kb", { params })

      if (params?.page > 1) {
        setKnowledgeList([...knowledgeList, ...data.result])
      } else {
        setKnowledgeList(data.result)
      }
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    } finally {
      setIsLocalLoading(false)
    }
  }
  const checkIsAuthenticated = async () => {
    setIsCheckingAuthentication(true)
    try {
      const { data } = await request.get("/third_party/servicenow/is_need_servicenow_oauth")
      setIsCheckingAuthentication(false)
      return !data.text
    } catch (error) {
      setError(true)
      setErrorMsg(error.message)
    }
  }
  const handleChangeType = async (event) => {
    const type = event.target.value

    setSelectedItems({})
    setQuery("")
    setSelectedType(type)
    if (type === "knowledge" && !knowledgeList.length) {
      fetchKnowledgeList({
        page_size: PAGE_SIZE,
        page: 1,
      })
    } else if (type === "incident" && !incidentList.length) {
      fetchIncidentList({
        page_size: PAGE_SIZE,
        page: 1,
      })
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      setSelectedItems({})
      setQuery("")
      fetchIncidentList({
        page_size: PAGE_SIZE,
        page: 1,
      })
    }
  }, [isIncremental])
  useEffect(() => {
    handleIdSelection({
      name: `${selectedItems?.number} - ${selectedItems?.short_description}` || "",
      ids: selectedItems?.sys_id || "",
      type: selectedType,
    })
  }, [selectedItems])
  useEffect(() => {
    const asyncUseEffect = async () => {
      const isAuthenticated = await checkIsAuthenticated()
      if (isAuthenticated) {
        setIsAuthenticated(true)
        fetchKnowledgeList({
          page_size: PAGE_SIZE,
          page: 1,
          query,
        })
      }
    }

    asyncUseEffect()
  }, [])

  return (
    <section style={{ marginTop: "1.5rem" }}>
      {isCheckingAuthentication ? (
        <Button>
          <CircularProgress size={24} />
        </Button>
      ) : isAuthenticated ? (
        <>
          <h5>Type</h5>
          <FormControl>
            <RadioGroup value={selectedType} onChange={handleChangeType} row>
              <FormControlLabel value="knowledge" control={<Radio />} label="Knowledge" />
              <FormControlLabel value="incident" control={<Radio />} label="Incident" />
            </RadioGroup>
          </FormControl>
          {selectedType === "knowledge" && (
            <>
              <h5>Select Knowledge</h5>
              <Autocomplete
                value={selectedItems}
                open={isLoadMore}
                onOpen={() => setIsLoadMore(true)}
                onClose={(_, reason) => {
                  if (reason === "blur") {
                    setIsLoadMore(false)
                  }
                }}
                onChange={(_, value) => {
                  if (value?.number) {
                    setSelectedItems(value)
                    setQuery(value?.number)
                    setIsLoadMore(false)
                  } else if (value?.loadMore) {
                    fetchKnowledgeList({
                      page_size: PAGE_SIZE,
                      page: knowledgePage + 1,
                      query,
                    })
                    setKnowledgePage(knowledgePage + 1)
                  }
                }}
                inputValue={query}
                onInputChange={(_, value, reason) => {
                  if (reason === "input") {
                    setQuery(value)
                    debounceFetchList({
                      page_size: PAGE_SIZE,
                      page: 1,
                      query: value,
                    })
                  } else if (reason === "clear") {
                    setQuery("")
                    debounceFetchList({
                      page_size: PAGE_SIZE,
                      page: 1,
                    })
                  }
                }}
                options={[...knowledgeList, { loadMore: true }]}
                getOptionLabel={(option) => option?.number || ""}
                filterOptions={(x) => x}
                renderOption={(props, option) =>
                  option.number ? (
                    <li {...props} key={`${option.number}`}>
                      <div style={{ color: "#3d3d3d80", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <div style={{ color: "#3d3d3d" }}>{option.number}</div>
                        <small style={{ whiteSpace: "nowrap" }}>{option.short_description}</small>
                      </div>
                    </li>
                  ) : (
                    <li {...props}>
                      {isLocalLoading ? <Skeleton variant="text" width="100px" height={20} /> : "Load More..."}
                    </li>
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" placeholder="Search knowledge..." />}
              />
            </>
          )}
          {selectedType === "incident" && (
            <>
              <h5>Select Incident</h5>
              <Autocomplete
                value={selectedItems}
                open={isLoadMore}
                onOpen={() => setIsLoadMore(true)}
                onClose={(_, reason) => {
                  if (reason === "blur") {
                    setIsLoadMore(false)
                  }
                }}
                onChange={(_, value) => {
                  if (value?.number) {
                    setSelectedItems(value)
                    setQuery(value?.number)
                    setIsLoadMore(false)
                  } else if (value?.loadMore) {
                    const nextPage = isIncremental ? incrementalPage + 1 : incidentPage + 1

                    fetchIncidentList({
                      page_size: PAGE_SIZE,
                      page: nextPage,
                      query,
                    })
                    if (isIncremental) {
                      setIncrementalPage(nextPage)
                    } else {
                      setIncidentPage(nextPage)
                    }
                  }
                }}
                inputValue={query}
                onInputChange={(_, value, reason) => {
                  if (reason === "input") {
                    setQuery(value)
                    debounceFetchList({
                      page_size: PAGE_SIZE,
                      page: 1,
                      query: value,
                    })
                  } else if (reason === "clear") {
                    setQuery("")
                    debounceFetchList({
                      page_size: PAGE_SIZE,
                      page: 1,
                    })
                  }
                }}
                options={[...incidentList, { loadMore: true }]}
                getOptionLabel={(option) => option?.number || ""}
                filterOptions={(x) => x}
                renderOption={(props, option) =>
                  option.number ? (
                    <li {...props} key={`${option.number}`}>
                      <div style={{ color: "#3d3d3d80", overflow: "hidden", textOverflow: "ellipsis" }}>
                        <div style={{ color: "#3d3d3d" }}>{option.number}</div>
                        <small style={{ whiteSpace: "nowrap" }}>{option.short_description}</small>
                      </div>
                    </li>
                  ) : (
                    <li {...props}>
                      {isLocalLoading ? <Skeleton variant="text" width="100px" height={20} /> : "Load More..."}
                    </li>
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" placeholder="Search Incident..." />}
              />
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  control={<Checkbox checked={isIncremental} onChange={(e) => setIsIncremental(e.target.checked)} />}
                  label="Incremental"
                />
              </div>
            </>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          onClick={authenticateServiceNow}
          disabled={authenticating}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {authenticating ? <CircularProgress size={24} /> : "Connect ServiceNow"}
        </Button>
      )}
    </section>
  )
}
