import React, { useState, useEffect, useContext } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { Button } from "@mui/material"

// Component Imports
import { AuthContext } from "../helper/AuthProvider"
import { LoadingContext } from "../helper/LoadingContext"
import { SuccessContext, ErrorContext } from "../helper/AlertContext"
import InputField from "../components/items/InputField"
import GoogleLoginComponent from "../components/items/GoogleLogin"
import SsoLoginComponent from "../components/items/SsoLogin"
import request from "../api/axios"
import fetchRequest from "../api/fetch"
import { accountService } from "../api/services"
import { isEnterpriseUser } from "../utils"
import { CUSTOM_ERR_MSG } from "../utils/constants"

async function getCountry(retries = 3) {
  while (retries > 0) {
    try {
      const response = await fetch("https://ipapi.co/json/")
      if (!response.ok) throw new Error(`HTTP status ${response.status}`)
      const data = await response.json()
      return data.country_name
    } catch (error) {
      console.error(`Failed to fetch country: ${error.message}`)
      retries--
      if (retries === 0) return null
    }
  }
}

export default function ActivateAccount({ setProfile, url }) {
  const { setAuth } = useContext(AuthContext)
  const loadingContext = useContext(LoadingContext)
  const successContext = useContext(SuccessContext)
  const errorContext = useContext(ErrorContext)
  const [user, setUser] = useState({})
  const [newPass, setNewPass] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [isNameExisted, setIsNameExisted] = useState(false)
  const [alreadyActivated, setAlreadyActivated] = useState(false)
  const [invalidSession, setInvalidSession] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)
  const [finishedChecking, setFinishedChecking] = useState(false)
  const [inputTouched, setInputTouched] = useState({ firstname: false, lastname: false })
  const { userId } = useParams()

  const navigate = useNavigate()

  // Password validation
  useEffect(() => {
    if (newPass === confirmPassword && newPass.length >= 12) {
      setIsPasswordConfirmed(true)
    } else {
      setIsPasswordConfirmed(false)
    }

    if (newPass.length >= 12) {
      setIsValidPassword(true)
    } else {
      setIsValidPassword(false)
    }
  }, [newPass, confirmPassword])

  const handleBlur = (field) => {
    setInputTouched((prev) => ({
      ...prev,
      [field]: true,
    }))
  }

  // Login action
  const finalizeLogin = () => {
    localStorage.setItem("profile", JSON.stringify(user))
    document.cookie = "userLoggedIn=true;path=/;"
    setProfile(user)
    setAuth(true)
    navigate(`/dashboard`)
    loadingContext.setIsLoading(false)
    successContext.setSuccess(true)
    successContext.setSuccessMsg("Your account has been successfully activated.")
  }


  const activateAccount = async () => {
    loadingContext.setIsLoading(true)
    try {
      let fullname = {
        first_name: firstname,
        last_name: lastname,
      }
      let password = {
        password: newPass,
      }
      await fetchRequest(`/account`, {
        method: "PUT",
        body: JSON.stringify(fullname),
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
      await request.post(`/changepassword/${userId}`, password)
      finalizeLogin()
    } catch (error) {
      if (error.response && error.response.data.message === "not correct user id") {
        errorContext.setError(true)
        errorContext.setErrorMsg(CUSTOM_ERR_MSG.activation_incorrect_userid)
      } else {
        errorContext.setError(true)
        errorContext.setErrorMsg(error.message)
      }
      loadingContext.setIsLoading(false)
    }
  }

  const activateUser = async () => {
    let checkData

    loadingContext.setIsLoading(true)
    try {
      const activationResponse = await request.post(`/activate/${userId}`)
      const userEmail = activationResponse.data.text.email

      checkData = {
        email: userEmail
      }

      await accountService.checkAccount(checkData)
    } catch (error) {
      if (error.status !== 406) {
        if (error.response?.data?.message === "already activate") {
          setAlreadyActivated(true)
          errorContext.setError(true)
          errorContext.setErrorMsg(CUSTOM_ERR_MSG.activation_already_activated)
        } else if (error.response?.data?.message === "not correct user id" || error.response?.data?.message === "cannot find user") {
          setInvalidSession(true)
          errorContext.setError(true)
          errorContext.setErrorMsg(CUSTOM_ERR_MSG.activation_incorrect_userid)
        } else {
          setInvalidSession(true)
          errorContext.setError(true)
          errorContext.setErrorMsg(error.response?.data?.message)
        }
        return
      }
    } finally {
      loadingContext.setIsLoading(false)
      setFinishedChecking(true)
    }
  }

  useEffect(() => {
    activateUser()
  }, [])

  return (
    <div className="login-wrap">
      {isEnterpriseUser() && isEnterpriseUser() !== "test" ? (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2rem"}}>
          <img src="/images/vext_logo.png" alt="vext logo" style={{width: "8rem"}}/>
          <img src={`/images/enterprise/${isEnterpriseUser()}.png`} style={{width: "10rem"}}/>
        </div>
          ) : <img className="login-logo" src="/images/vext_logo.png" alt="vext logo" />}
      <div className="login-container">
        {finishedChecking ? (
          !alreadyActivated ? (
            !invalidSession ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {isEnterpriseUser() ? (
                  <>
                    <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Activate Your Vext Account</p>
                    <div className="sso-wrap login">
                      <SsoLoginComponent url={url} finalizeLogin={finalizeLogin} getCountry={getCountry}/>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <p style={{ margin: "auto", fontSize: "1.2rem", fontWeight: "700" }}>Activate Your Account</p>
                      <div style={{margin: "auto"}}>
                        <GoogleLoginComponent url={url} finalizeLogin={finalizeLogin} getCountry={getCountry} />
                      </div>
                      <div style={{marginBottom: "0.5rem", display: "flex", justifyContent: "center"}}>
                        <hr style={{width: "70%", borderTop: "1px solid #3d3d3d30", position: "absolute"}}/>
                        <p style={{fontSize: "0.7rem", zIndex: "2", backgroundColor: "#ffffff", padding: "0 0.5rem"}}>or create an account</p>
                      </div>
                      {!isNameExisted && (
                        <>
                          <div>
                            <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>First Name</p>
                            <InputField
                              placeholder="First Name"
                              onChange={setFirstname}
                              onBlur={() => handleBlur("firstname")}
                              invalid={!firstname && inputTouched.firstname}
                              disabled={loadingContext.isLoading}
                              autoComplete="off"
                            />
                          </div>
                          <div>
                            <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Last Name</p>
                            <InputField
                              placeholder="Last Name"
                              onChange={setLastname}
                              onBlur={() => handleBlur("lastname")}
                              invalid={!lastname && inputTouched.lastname}
                              disabled={loadingContext.isLoading}
                              autoComplete="off"
                            />
                          </div>
                        </>
                      )}
                      <div>
                        <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Password</p>
                        <InputField
                          placeholder="Password"
                          type="password"
                          onChange={setNewPass}
                          invalid={!isValidPassword && newPass !== ""}
                          disabled={loadingContext.isLoading}
                          autoComplete="off"
                        />
                        <p style={{ fontSize: "0.7rem", marginTop: "0.3rem" }}>At least 12 characters</p>
                      </div>
                      <div>
                        <p style={{ fontSize: "0.6rem", fontWeight: "700", marginBottom: "0.3rem" }}>Confirm Password</p>
                        <InputField
                          placeholder="Confirm Password"
                          type="password"
                          onChange={setConfirmPassword}
                          invalid={newPass !== confirmPassword && confirmPassword !== ""}
                          disabled={loadingContext.isLoading}
                          autoComplete="off"
                          onPressEnter={(e) => {
                            if (isPasswordConfirmed) {
                              activateAccount(e)
                            }
                          }}
                        />
                      </div>
                      <Button
                        variant="contained"
                        style={{ width: "100%" }}
                        disabled={!isPasswordConfirmed || !firstname || !lastname || loadingContext.isLoading}
                        onClick={activateAccount}
                      >
                        {loadingContext.isLoading ? "Loading..." : "Set Password"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  textAlign: "center",
                }}
              >
                <p style={{ fontSize: "1.2rem", fontWeight: "700", justifyContent: "center" }}>Invalid or Expired Session</p>
                <p style={{ justifyContent: "center" }}>
                  Please request a new activation link or contact support.
                </p>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={() => navigate("/login")}
                >
                  Return to Login
                </Button>
              </div>
            )
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                textAlign: "center",
              }}
            >
              <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>This account has already been activated.</p>
              <p style={{ justifyContent: "center" }}>Please login to continue.</p>
              <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={() => navigate("/login")}
                >
                  Return to Login
                </Button>
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              textAlign: "center",
            }}
          >
            <p style={{ justifyContent: "center", fontSize: "1.2rem", fontWeight: "700" }}>
              Validating... Please Wait.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}
