// Essential Imports
import React from "react"

// Component Imports
import LocalLoadingBar from "./LocalLoadingBar"

// Library Imports
import Backdrop from "@mui/material/Backdrop"
import Box from "@mui/material/Box"

const BackdropComponent = ({ open, handleClose, localLoading, children, width = "25rem", zIndex }) => {
  return (
    <Backdrop open={open} onClick={handleClose} sx={{ zIndex: zIndex ? zIndex : (theme) => theme.zIndex.drawer + 1 }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          p: "2rem",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: width,
          textAlign: "center",
          gap: "1rem",
          position: "relative",
          overflow: "hidden",
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div>
          <LocalLoadingBar localLoading={localLoading} />
          {children}
        </div>
      </Box>
    </Backdrop>
  )
}

export default BackdropComponent
